/**
 * HACK: getViewportSize is a small utility to get the current viewport size
 * as an enum value (s, m, l, xl, unknown)
 *
 * See: https://web-ui.ef.design/layout/grid
 */
const getViewportSize = () => {
  const width = window.innerWidth;

  if (typeof width === 'undefined') {
    return 'unknown';
  }

  if (width < 768) {
    return 's';
  }

  if (width < 1023) {
    return 'm';
  }

  if (width < 1263) {
    return 'l';
  }

  return 'xl';
};

export { getViewportSize };
