import React from 'react';
import { IntlProvider } from 'react-intl';
import { DEFAULT_INTL_CONFIG } from 'react-intl/src/utils';
import { logger } from '../../utils/logger';
import { Main } from '../lib/Layout/Main';

/**
 * ErrorWrapper provides a Main wrapper for error pages, both full screen and page-level. This gives error pages the AppFooter
 * and some basic style.
 */
const ErrorWrapper = ({ children }: { children?: React.ReactNode }) => {
  const correlationId = logger.getCorrelationId();

  return (
    // FIXME: This IntlProvider does not actually work. We need to internationalize the NotFoundError, so this should be fixed.
    <IntlProvider locale={navigator.language} defaultLocale={DEFAULT_INTL_CONFIG.defaultLocale}>
      <Main fullPage>
        <div className='ef-container'>
          <div className='u-s-p-l u-mt-l u-mb-l u-text-center -x-center'>
            {children}
            <div className={'u-s-mt-xl'}>
              <small>
                <em>{correlationId !== undefined ? <>Request Id: {correlationId}</> : null}</em>
              </small>
            </div>
          </div>
        </div>
      </Main>
    </IntlProvider>
  );
};

export { ErrorWrapper };
