import { createLogger, Environments } from '@eftours/web-logger-typescript';
import { Metric } from 'web-vitals';
import { resolve } from './config';
import { reportWebVitals, WebVitals } from './reportWebVitals';
import { getViewportSize } from './window';

const CLIENT_EVENT_LOG_TYPE = 'ClientEvent';

/**
 * logger is our application-level logger instance. The following env vars need to be
 * set.
 *
 * REACT_APP_LOGGER_SERVICE
 * REACT_APP_LOGGER_ENVIRONMENT
 * REACT_APP_LOGGER_ROLLBAR_ACCESS_TOKEN
 * REACT_APP_LOGGER_BEACON_ENDPOINT?
 */
const logger = createLogger({
  service: process.env.REACT_APP_LOGGER_SERVICE || 'traveler-experience',
  environment: (process.env.REACT_APP_LOGGER_ENVIRONMENT || 'unknown') as Environments,
  rollbar: {
    accessToken: process.env.REACT_APP_LOGGER_ROLLBAR_ACCESS_TOKEN || '',
  },
  beacon: {
    endpoint: resolve(process.env.REACT_APP_LOGGER_BEACON_ENDPOINT),
  },
});

/**
 * Do a few things when first creating the logger
 */
const initializeClientLoggerMeta = () => {
  // Set an initial page load correlation id;
  logger.setCorrelationId();

  logger.appendMeta({
    // Set a sessionId for all future events regardless of the current correlationId
    sessionId: logger.getCorrelationId(),
  });

  reportWebVitals(({ id, name, value, delta }: Metric) => {
    logger.log({
      level: 'info',
      logType: 'WebVitals',
      message: WebVitals[name] || 'Unknown Web Vital name',
      event: name,
      eventId: id,
      eventValue: value,
      eventDelta: delta,
    });
  });

  // Log some device/user information as soon as the app renders.
  clientEvent('appInitialization', {
    // Copy the current UA.
    // FIXME: We should be migrating to navigator.userAgentData, but not all browsers support it yet
    // See: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData
    userAgent: navigator.userAgent,

    // Does this device have multiple touch points? i.e., phone or tablet or fancy laptop.
    // Where this is undefined won't be a touch screen either.
    touchScreen: navigator.maxTouchPoints > 1,

    // FIXME: We should consider using window.visualViewport, but this is how our CSS is rendering
    // so it probably makes more sense.
    viewport: getViewportSize(),

    // We want to know what page referred the user to this page/app (backend api can't figure this out)
    referrer: document.referrer,

    // We're curious to see what languages our users prefer based on browser setting.
    // Can have multiple preferred langs, but fall back to `navigator.language` if
    // `navigator.languages` isn't available.
    languages: navigator.languages || [navigator.language],
  });
};

/**
 * clientEvent logs a named client event with the given meta. meta can be overwritten
 * if it collides with the given fields: logType, event.
 *
 * @param name String name to reference the event
 * @param meta Key/value object of additional information
 */
const clientEvent = async (name: string, meta: Record<string, any>) => {
  logger.log({
    level: 'info',
    message: '',
    ...meta,
    logType: CLIENT_EVENT_LOG_TYPE,
    event: name,
  });
};

// go ahead and run logger init meta while this module is resolved
initializeClientLoggerMeta();

export { logger, clientEvent };
