import { generatePath } from 'react-router-dom';
import { RouteConfig, RouteObject } from './config';

/**
 * ExtractRouteParams is a utility type to transform a given route into a string
 * union of URL params. This is required for the PathGenerator pattern to work
 * with React v6's new types.
 *
 * @example
 * // Create a type of `'id' | 'action'`
 * type RouteParams = ExtractRouteParams<'/some/:id/:action'>;
 */
type ExtractRouteParams<T extends string> = T extends `${infer Start}/:${infer Param}/${infer Rest}`
  ? ExtractRouteParams<Start> | Param | ExtractRouteParams<Rest>
  : T extends `${infer Start}/:${infer Param}`
  ? ExtractRouteParams<Start> | Param
  : T extends `:${infer Param}/${infer Rest}`
  ? Param | ExtractRouteParams<Rest>
  : T extends `:${infer Param}`
  ? Param
  : never;

/**
 * ParamsWithNumbers is a utility type to map a string type ('param1' | 'param2')
 * to an object of { key: number | string | undefined }. This is a shim to support
 * numbers in PathGenerators despite react-router v6 not expecting them.
 */
type ParamsWithNumbers<Key extends string> = string extends Key
  ? Record<string, never>
  : {
      [key in Key]: number | string | undefined;
    };

/**
 * PathGenerator is a generic type for a function that constructs a path
 * based on a given route and params.
 */
type PathGenerator<T extends string = string> = (params?: ParamsWithNumbers<ExtractRouteParams<T>>) => string;

type PathGenerators<T extends RouteConfig | undefined> = T extends RouteConfig
  ? {
      [Route in keyof T]: PathGenerator<T[Route]['path'] extends string ? T[Route]['path'] : string>;
    }
  : string;

/**
 * createPathGenerator returns a PathGenerator instance for a given route.
 * It's a curry function for `generatePath` in `react-router`.
 *
 * @param params { [param: string]: string} object of url path params. If excluded,
 * the raw path is returned (/some/:id vs /some/123)
 */
const createPathGenerator =
  <T extends string>(path: T): PathGenerator<T> =>
  (params) => {
    if (params === undefined) {
      return path;
    }

    // Convert any of our non string params to strings because react-router v6 expects
    // string | undefined now instead of number | string | undefined. This is mainly
    // for things like paxOrderId
    const stringParams: Record<string, string | undefined> = {};
    for (const key in params) {
      stringParams[key] = params[key]?.toString();
    }
    return generatePath(path, stringParams);
  };

// The following utility types are meant ot only be used with ExtractChildRouteConfig. These
// are required to strongly type our URLs while using nested routes to use react-router v6
// outlets. For historic context of where these types came from see:
// https://github.com/microsoft/TypeScript/issues/31192
type ObjKeyof<T> = T extends Record<string, unknown> ? keyof T : never;
type KeyofKeyof<T> = ObjKeyof<T> | { [K in keyof T]: ObjKeyof<T[K]> }[keyof T];
type StripNever<T> = Pick<T, { [K in keyof T]: [T[K]] extends [never] ? never : K }[keyof T]>;
type Lookup<T, K> = T extends any ? (K extends keyof T ? T[K] : never) : never;
type SimpleFlatten<T> = T extends Record<string, unknown>
  ? StripNever<
      {
        [K in KeyofKeyof<T>]:
          | Exclude<K extends keyof T ? T[K] : never, Record<string, unknown>>
          | { [P in keyof T]: Lookup<T[P], K> }[keyof T];
      }
    >
  : T;

/**
 * ExtractChildRouteConfig is a utility type to flatten a given route config one level
 * and returns all children as a single top-level object. Because SimpleFlatten is a
 * generic flatten util, remove any lingering keys from the parent routes.
 *
 * DO NOT USE DIRECTLY
 */
type ExtractChildRouteConfig<T extends RouteConfig> = Readonly<
  Omit<SimpleFlatten<SimpleFlatten<T>>, keyof RouteObject>
>;

/**
 * RouteConfigToPathGenerators is a utility type to flatten a RouteConfig into a
 * map of key to PathGenerator. Only one level of nesting is supported.
 */
type RouteConfigToPathGenerators<T extends RouteConfig> = PathGenerators<T> &
  PathGenerators<ExtractChildRouteConfig<T>>;

/**
 * createRouteGenerators returns a type safe mapping our path name to
 * path generator from a given RouteConfig.
 *
 * @see createPathGenerator
 */
const createRouteGenerators = <T extends RouteConfig>(config: T) => {
  // Use a value correct internal type while compiling the actual
  // path generators for each route.
  let routes: Record<string, PathGenerator<string>> = {};

  for (const route in config) {
    const path = config[route].path || '';
    routes[route] = createPathGenerator(path);
    const children = config[route].children;
    if (children !== undefined) {
      routes = { ...routes, ...createRouteGenerators(children) };
    }
  }

  // Cast to the correct type we expect for consumers.
  return routes as RouteConfigToPathGenerators<T>;
};

export type { PathGenerator, PathGenerators, RouteConfigToPathGenerators, ExtractRouteParams };
export { createPathGenerator, createRouteGenerators };
