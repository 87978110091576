import { lazy } from 'react';
import store from 'store2';

/**
 * lazyWithRetry is a wrapper for React's lazy import which allows code splitting at build time
 * see: https://reactjs.org/docs/code-splitting.html.
 * This wrapper function is meant to limit error boundary exceptions from when an expired import
 * chunk is requested, by refreshing the page once on failed import. It uses session storage
 * to limit this to just one refresh per import failure.
 */
const REFRESH_KEY = 'page-refreshed';
const lazyWithRetry = (pageImport: () => any) =>
  lazy(async () => {
    const refreshAttempted = JSON.parse(store.session.get(REFRESH_KEY) || 'false');

    try {
      const page = await pageImport();

      store.session.set(REFRESH_KEY, 'false');
      return page;
    } catch (error) {
      if (!refreshAttempted) {
        // Assuming that the user is not on the latest version of the application.
        // Refresh the page immediately.
        store.session.set(REFRESH_KEY, 'true');
        return window.location.reload();
      }

      // The page has already been reloaded, which should ensure the latest version is being used
      // Throw exception
      throw error;
    }
  });

export { lazyWithRetry };
