import classNames from 'classnames';

/**
 * FIXME: This implementation is an altered version of the one that's in @ef-global/web-ui,
 *
 * Currently there is an open issue (https://github.com/ef-global/ef-web-ui/issues/53) that essentially makes their
 * <Button> react component unusable and there has been no indication that it will be fixed any time soon
 *
 * This implementation takes their component (https://github.com/ef-global/ef-web-ui/blob/master/%40ef-global/web-ui-react/src/components/Button.tsx),
 * which is a wrapper around the CSS implementation (https://web-ui.ef.design/components/button?version=css),
 * and fixes the typing issues
 */

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  React.AnchorHTMLAttributes<HTMLAnchorElement> &
  React.InputHTMLAttributes<HTMLInputElement> & {
    primary?: boolean;
    secondary?: boolean;
    tertiary?: boolean;
    icon?: boolean;
    filled?: boolean;
    square?: boolean;
    small?: boolean;
    medium?: boolean;
    large?: boolean;
    xlarge?: boolean;
    cta?: boolean;
    loading?: boolean;
    arrow?: boolean;
    link?: boolean;
    as?: 'button' | 'a' | 'input';
  };

const Button = ({
  as = 'button',
  className,
  primary,
  secondary,
  icon,
  filled,
  square,
  small,
  medium,
  large,
  xlarge,
  cta,
  children,
  loading,
  tertiary,
  arrow,
  link,
  ...rest
}: ButtonProps) => {
  const classes = classNames(
    'ef-button',
    {
      '-primary': primary,
      '-outlined': secondary, // secondary prop adds -outlined class in GUD, not -secondary
      '-secondary': tertiary, // since secondary is -outlined, tertiary is -secondary
      '-large': cta,
      '-filled': filled,
      '-square': square,
      '-small': small,
      '-is-loading': loading,
      'ef-icon__container': icon,
      '-s': icon && small,
      '-m': icon && medium,
      '-l': icon && large,
      '-xl': icon && xlarge,
      '-link-arrow': arrow,
      '-link': link,
    },
    className
  );

  const props = {
    className: classes,
    ...rest,
  };

  switch (as) {
    case 'input': {
      return <input {...props} />;
    }
    case 'a': {
      return (
        <a {...props}>
          <span className='ef-button__content'>{children}</span>
        </a>
      );
    }
    default: {
      return (
        <button {...props}>
          <span className='ef-button__content'>{children}</span>
        </button>
      );
    }
  }
};

export type { ButtonProps };
export { Button };
