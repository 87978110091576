/* eslint-disable import/no-cycle */

import React from 'react';
import { Navigate } from 'react-router';
import { createPageWrapper } from './Page';
import { createRouteProvider } from './router/RouteProvider';
import { lazyWithRetry } from './lazyImport';

const BASE_PATH = '/tours/:paxOrderId' as const;
const DONATIONS_BASE_PATH = '/donations/:encryptedPaxOrderId' as const;
const ON_TOUR_PURCHASE_BASE_PATH = '/on-tour-purchase/:encryptedGroupTripId' as const;

/**
 * Page is a wrapper component that uses Suspense as well as optionally requiring booking conditions
 * to be present before rendering a given page. The wrapper is created here to avoid cyclic
 * dependencies on useRoutes.
 *
 * @example
 * // Wrap a lazy loaded component with Suspense
 * <Page><SomeLazyLoadedComponent></Page>
 *
 * @example
 * // Require booking conditions to be signed for a given route
 * <Page requireBookingConditions><SomeLazyLoadedComponent></Page>
 */
const Page = createPageWrapper({
  bookingConditionsRedirect: ({ paxOrderId }) => {
    const { BOOKING_CONDITIONS } = useRoutes();
    return BOOKING_CONDITIONS({ paxOrderId });
  },
});

// Lazy imports enables code splitting during build time. See: https://reactjs.org/docs/code-splitting.html
// Page imports:
const OneTimePaymentRedirectPage = lazyWithRetry(
  () => import('./pages/OneTimePaymentRedirect/OneTimePaymentRedirectPage')
);
const LinksPage = lazyWithRetry(() => import('./pages/Links/LinksPage'));
const TestOtpPage = lazyWithRetry(() => import('./pages/TestOtp/TestOtpPage'));
const OnTourPurchasePage = lazyWithRetry(() => import('./pages/OnTourPurchase/OnTourPurchasePage'));
const NotFoundPage = lazyWithRetry(() => import('./components/Error/NotFoundError'));
const App = lazyWithRetry(() => import('./App'));
const OverviewPage = lazyWithRetry(() => import('./pages/Overview/OverviewPage'));
const ItineraryPage = lazyWithRetry(() => import('./pages/Itinerary/ItineraryPage'));
const AddExcursionPage = lazyWithRetry(() => import('./pages/AddExcursion/AddExcursionPage'));
const RemoveExcursionPage = lazyWithRetry(() => import('./pages/RemoveExcursion/RemoveExcursionPage'));
const SpecialTravelRequestLandOnlyPage = lazyWithRetry(
  () => import('./pages/SpecialTravelRequest/LandOnlyRequest/LandOnlyPage')
);
const SpecialTravelRequestPage = lazyWithRetry(
  () => import('./pages/SpecialTravelRequest/SpecialTravelRequest/SpecialTravelRequestPage')
);
const SpecialTravelRequestCancelPage = lazyWithRetry(() => import('./pages/SpecialTravelRequest/Cancel/CancelPage'));
const SpecialTravelRequestTermsAndConditionsPage = lazyWithRetry(
  () => import('./pages/SpecialTravelRequest/SpecialTravelRequest/TermsAndConditionsPage')
);
const LandOnlyTermsAndConditionsPage = lazyWithRetry(
  () => import('./pages/SpecialTravelRequest/LandOnlyRequest/TermsAndConditionsLandOnlyPage')
);
const PaymentsPage = lazyWithRetry(() => import('./pages/Payments/PaymentsPage'));
const SafetyPage = lazyWithRetry(() => import('./pages/Safety/SafetyPage'));
const TravelProtectionPage = lazyWithRetry(() => import('./pages/TravelProtection/TravelProtectionPage'));
const AddTravelProtectionPage = lazyWithRetry(() => import('./pages/AddTravelProtection/AddTravelProtectionPage'));
const TodosPage = lazyWithRetry(() => import('./pages/Todos/TodosPage'));
const BookingConditionsPage = lazyWithRetry(() => import('./pages/BookingConditions/BookingConditionsPage'));
const DonationBase = lazyWithRetry(() => import('./pages/Donations/DonationBase'));
const DonationInfoPage = lazyWithRetry(() => import('./pages/Donations/DonationInfo/DonationInfoPage'));
const DonationPaymentPage = lazyWithRetry(() => import('./pages/Donations/DonationPayment/DonationPaymentPage'));
const FinalItineraryPage = lazyWithRetry(() => import('./pages/FinalItinerary/FinalItineraryPage'));
const EntryRequirementsPage = lazyWithRetry(() => import('./pages/EntryRequirements/EntryRequirementsPage'));
const IdConfirmationPage = lazyWithRetry(() => import('./pages/IdConfirmation/IdConfirmationPage'));
const ReportChangeCard = lazyWithRetry(() => import('./pages/IdConfirmation/NameConfirmation/ReportNameChangePage'));
const UpdatePassportPage = lazyWithRetry(
  () => import('./pages/IdConfirmation/PassportVerification/UpdatePassportPage')
);
const NameConfirmationPage = lazyWithRetry(
  () => import('./pages/IdConfirmation/NameConfirmation/NameConfirmationPage')
);

/**
 * config contains the route configurations for our application. If an element is
 * lazy loaded, it must be wrapped in a `<PageWithSuspense>`. Routes can only be
 * nested 1-level by design.
 *
 * Use relative paths for nested child to simplify link creation.
 */
const config = {
  NOT_FOUND: {
    path: `/not-found`,
    element: (
      <Page name={'notFound'}>
        <NotFoundPage />
      </Page>
    ),
  },
  // Redirect to /not-found if no better path is available
  DEFAULT: {
    path: '*',
    element: <Navigate to='/not-found' replace />,
  },
  // APP is the root route of the application where paxOrderId is a required
  // route param to load content.
  APP: {
    path: `${BASE_PATH}`,
    element: (
      <Page name={'app'}>
        <App />
      </Page>
    ),
    children: {
      // App routes
      LINKS: {
        path: `${BASE_PATH}/links`,
        element: (
          <Page name={'links'}>
            <LinksPage />
          </Page>
        ),
      },
      OVERVIEW: {
        path: `${BASE_PATH}`,
        element: (
          <Page name={'overview'} requireBookingConditions>
            <OverviewPage />
          </Page>
        ),
      },
      ITINERARY: {
        path: `${BASE_PATH}/itinerary`,
        element: (
          <Page name={'itinerary'} requireBookingConditions>
            <ItineraryPage />
          </Page>
        ),
      },
      FINAL_ITINERARY: {
        path: `${BASE_PATH}/itinerary/flights-and-hotels`,
        element: (
          <Page name={'finalItinerary'} requireBookingConditions>
            <FinalItineraryPage />
          </Page>
        ),
      },
      ADD_EXCURSION: {
        path: `${BASE_PATH}/itinerary/add/:matchCode`,
        element: (
          <Page name={'addExcursion'} requireBookingConditions>
            <AddExcursionPage />
          </Page>
        ),
      },
      REMOVE_EXCURSION: {
        path: `${BASE_PATH}/itinerary/remove/:matchCode`,
        element: (
          <Page name={'removeExcursion'} requireBookingConditions>
            <RemoveExcursionPage />
          </Page>
        ),
      },
      SPECIAL_TRAVEL_REQUEST_LAND_ONLY: {
        path: `${BASE_PATH}/itinerary/land-only-request`,
        element: (
          <Page name={'specialTravelRequestLandOnly'} requireBookingConditions>
            <SpecialTravelRequestLandOnlyPage />
          </Page>
        ),
      },
      SPECIAL_TRAVEL_REQUEST: {
        path: `${BASE_PATH}/itinerary/special-travel-request`,
        element: (
          <Page name={'specialTravelRequest'} requireBookingConditions>
            <SpecialTravelRequestPage />
          </Page>
        ),
      },
      SPECIAL_TRAVEL_REQUEST_CANCEL: {
        path: `${BASE_PATH}/itinerary/cancel-special-travel`,
        element: (
          <Page name={'cancelSpecialTravel'} requireBookingConditions>
            <SpecialTravelRequestCancelPage />
          </Page>
        ),
      },
      SPECIAL_TRAVEL_REQUEST_TERMS_AND_CONDITIONS: {
        path: `${BASE_PATH}/itinerary/special-travel-request-terms-and-conditions`,
        element: (
          <Page name={'specialTravelRequestTermsAndConditions'} requireBookingConditions>
            <SpecialTravelRequestTermsAndConditionsPage />
          </Page>
        ),
      },
      LAND_ONLY_TERMS_AND_CONDITIONS: {
        path: `${BASE_PATH}/itinerary/land-only-terms-and-conditions`,
        element: (
          <Page name={'landOnlyTermsAndConditions'} requireBookingConditions>
            <LandOnlyTermsAndConditionsPage />
          </Page>
        ),
      },
      PAYMENTS: {
        path: `${BASE_PATH}/payments`,
        element: (
          <Page name={'payments'} requireBookingConditions>
            <PaymentsPage />
          </Page>
        ),
      },
      SAFETY_AND_FORMS: {
        path: `${BASE_PATH}/safety-and-forms`,
        element: (
          <Page name={'safetyAndForms'} requireBookingConditions>
            <SafetyPage />
          </Page>
        ),
      },
      BOOKING_CONDITIONS: {
        path: `${BASE_PATH}/safety-and-forms/booking-conditions`,
        element: (
          <Page name={'bookingConditions'}>
            <BookingConditionsPage />
          </Page>
        ),
      },
      ENTRY_REQUIREMENTS: {
        path: `${BASE_PATH}/safety-and-forms/entry-requirements`,
        element: (
          <Page name={'entryRequirements'}>
            <EntryRequirementsPage />
          </Page>
        ),
      },
      TRAVEL_PROTECTION: {
        path: `${BASE_PATH}/travel-protection`,
        element: (
          <Page name={'travelProtection'} requireBookingConditions>
            <TravelProtectionPage />
          </Page>
        ),
      },
      ADD_TRAVEL_PROTECTION: {
        path: `${BASE_PATH}/travel-protection/add/:matchCode`,
        element: (
          <Page name={'addTravelProtection'} requireBookingConditions>
            <AddTravelProtectionPage />
          </Page>
        ),
      },
      TODOS: {
        path: `${BASE_PATH}/todos`,
        element: (
          <Page name={'todos'} requireBookingConditions>
            <TodosPage />
          </Page>
        ),
      },
      TRAVELER_INFO: {
        path: `${BASE_PATH}/traveler-info`,
        element: (
          <Page name={'idConfirmation'} requireBookingConditions>
            <IdConfirmationPage />
          </Page>
        ),
      },
      NAME_CONFIRMATION: {
        path: `${BASE_PATH}/traveler-info/name-confirmation`,
        element: (
          <Page name={'name-confirmation'} requireBookingConditions>
            <NameConfirmationPage />
          </Page>
        ),
      },
      REPORT_NAME_CHANGE: {
        path: `${BASE_PATH}/traveler-info/name-confirmation/update`,
        element: (
          <Page name={'name-update-needed'} requireBookingConditions>
            <ReportChangeCard />
          </Page>
        ),
      },
      ADD_DOCUMENT: {
        path: `${BASE_PATH}/traveler-info/add-document`,
        element: (
          <Page name={'add-document'} requireBookingConditions>
            <UpdatePassportPage />
          </Page>
        ),
      },
    },
  },
  DONATIONS: {
    path: `${DONATIONS_BASE_PATH}`,
    element: (
      <Page name={'donations'}>
        <DonationBase />
      </Page>
    ),
    children: {
      DONATION_INFO: {
        path: `${DONATIONS_BASE_PATH}`,
        element: (
          <Page name={'donationsInfo'}>
            <DonationInfoPage />
          </Page>
        ),
      },
      DONATION_PAYMENT: {
        path: `${DONATIONS_BASE_PATH}/payment`,
        element: (
          <Page name={'donationsPayment'}>
            <DonationPaymentPage />
          </Page>
        ),
      },
    },
  },
  ON_TOUR_PURCHASE: {
    path: `${ON_TOUR_PURCHASE_BASE_PATH}`,
    element: (
      <Page name={'OnTourPurchasePage'}>
        <OnTourPurchasePage />
      </Page>
    ),
  },
  ONE_TIME_PAYMENT_REDIRECT: {
    path: '/payment/:encryptedPaxOrderId',
    element: (
      <Page name={'oneTimePaymentRedirect'}>
        <OneTimePaymentRedirectPage />
      </Page>
    ),
  },
  // FIXME: remove this page/route when OTP is launched and it's no longer needed for testing
  TEST_OTP: {
    path: `/test-otp/:groupTripId`,
    element: (
      <Page name={'testOtp'}>
        <TestOtpPage />
      </Page>
    ),
  },
} as const;

const [useRoutes, RouteProvider] = createRouteProvider(config);

export { config, useRoutes, RouteProvider, BASE_PATH };
