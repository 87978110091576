import React, { useEffect, useContext } from 'react';
import { AsyncRequestState, useAsyncRequestState } from '../hooks/useAsyncRequestState';

/**
 * PageProviderError is a standard shape to describe errors. It will be updated later
 * to align with whatever is used in the backend.
 */
type PageProviderError = {
  rawError?: any;
  message: string;
};

/**
 * PageContext is a generic type to strongly type the data structure of a given
 * PageProvider. This page specific and will be used with createPageContext.
 *
 * An example without using the createPageContext helper:
 *
 * type OverviewPageData = {
 *   foo: string,
 *   bar: string,
 * }
 *
 * type OverviewPageContext = PageContext<OverviewPageData>
 */
type PageContext<T> = {
  isLoading: AsyncRequestState;
  data: T | null;
};

// NonNullableContext is only suitable for situations when data _cannot_ be null
// outside of runtime exceptions. Not runtime safe.
type NonNullableContext<T> = {
  data: T;
} & Omit<PageContext<T>, 'data'>;

/**
 * createPageContext is a generic React.Context and useContext hook factory. `T`
 * is the data type for a given page. For example:
 *
 * type OverviewPageData = {
 *   foo: string,
 *   bar: string
 * }
 *
 * const [OverviewContext, useOverviewContext] = createPageContext<OverviewPageData>();
 */
function createPageContext<T>(): [React.Context<PageContext<T>>, () => PageContext<T>] {
  const pageContext = React.createContext({
    isLoading: AsyncRequestState.NEVER,
    data: null,
  } as PageContext<T>);

  const usePageContext = () => useContext(pageContext);

  return [pageContext, usePageContext];
}

type PageProviderGetter<T> = () => Promise<T>;

/**
 * PageProviderProps is a generic prop type used with PageProvider. The T type param
 * is the data structure for the API response for a given page.
 */
type PageProviderProps<T> = {
  PageContext: React.Context<PageContext<T>>;
  getter: PageProviderGetter<T>;
  useAsync?: boolean;
  renderPageError?: (err: PageProviderError) => React.ReactNode;
  renderPageLoading?: (isLoading: AsyncRequestState) => React.ReactNode;
  children?: React.ReactNode;
};

/**
 * PageProvider is a generic component to provide children components with page-specific API data.
 *
 * By default, PageProvider _will not_ render children until it reaches a terminal state of
 * COMPLETE. Error and loading states are managed by PageProvider. If you want children components
 * to manage their own loading state, set useAsync to true.
 *
 * FIXME: Error typing needs to improve before deeper usage. Some additional features may be needed.
 */
function PageProvider<T>({
  PageContext,
  getter,
  useAsync,
  renderPageLoading = () => null,
  renderPageError = () => null,
  children,
}: PageProviderProps<T>) {
  const { request, requestState, data, error } = useAsyncRequestState(getter);

  useEffect(() => {
    request();
  }, []);

  if (requestState === AsyncRequestState.ERROR) {
    const errorObj: PageProviderError = {
      rawError: error,
      message: error?.message || 'Unknown error',
    };

    return error && <>{renderPageError(errorObj)}</>;
  } else if (requestState === AsyncRequestState.COMPLETE || (useAsync && AsyncRequestState.LOADING)) {
    return <PageContext.Provider value={{ data, isLoading: requestState }}>{children}</PageContext.Provider>;
  } else {
    return <>{renderPageLoading(requestState)}</>;
  }
}

/**
 * WrappedPageProviderProps represent the props used by the PageProvider
 * created and returned by createPageProvider.
 */
type WrappedPageProviderProps<T> = Pick<
  PageProviderProps<T>,
  'children' | 'getter' | 'renderPageLoading' | 'renderPageError'
>;

/**
 * WrappedPageProviderDefaultProps include the default props passed to
 * WrappedPageProviderProps in createPageProvider. This allows for default
 * loading and error renders.
 */
type WrappedPageProviderPropsDefaults<T> = Partial<
  Pick<WrappedPageProviderProps<T>, 'renderPageLoading' | 'renderPageError'>
>;

/**
 * createPageProvider is a helper to abstract away wiring up a PageProvider. Returns
 * a wrapped PageProvider for the given data type and getter. This provider _will not_
 * render children on anything other than COMPLETE and as `useAsync` set to false.
 */
function createPageProvider<T>(
  defaultProps: WrappedPageProviderPropsDefaults<T> = {}
): [() => NonNullableContext<T>, React.ComponentType<WrappedPageProviderProps<T>>, React.Context<PageContext<T>>] {
  // Create the context needed for this provider
  const [pageContext, usePageContext] = createPageContext<T>();

  // Create the component used for this specific provider
  const Provider = (pageProviderProps: WrappedPageProviderProps<T>) => {
    const combinedProps = { ...defaultProps, ...pageProviderProps };
    return <PageProvider<T> PageContext={pageContext} useAsync={false} {...combinedProps} />;
  };

  // useAsync is explicitly false, meaning usePageContext should never have
  // null data. Wrap the return type w/ NonNullable to simplify typing.
  // WARNING: runtime errors are still possible.
  const useNonNullableData = () => usePageContext() as NonNullableContext<T>;

  return [useNonNullableData, Provider, pageContext];
}

export type { PageContext, PageProviderGetter, WrappedPageProviderProps, PageProviderError, NonNullableContext };
export { PageProvider, createPageContext, createPageProvider };
