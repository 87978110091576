// To help with specificity of CSS modules, we need to import 3rd party CSS
// before our first component using a module.
import '@ef-global/web-ui/dist/css/latin.min.css';
import '@ef-global/web-ui/dist/css/bundle.css';
import './assets/scss/overrides.scss';
import './assets/scss/utils.scss';
// This is dirty copy of certain labs.ui-components styles we need on donations page only.
// It would be a waste of time to convert to CSS modules; it's not structured at all the same.
// Also let's delete all of this once we get an updated tokenizer form that uses Web-UI components instead.
import './components/Forms/LabsFormComponents/index.scss';

// FIXME: importing these here is preventing the mini-css-extract-plugin conflicting order issue.
import './components/lib/LinkWithIcon.module.scss';
import './components/Cards/CardHeader.module.scss';
import './components/Cards/Card.module.scss';
import './components/Cards/CardGroup.module.scss';
import './components/Cards/CardSection.module.scss';
import './components/MediaItem.module.scss';
import './components/lib/withStatusColorProps.module.scss';
import './components/lib/Status.module.scss';
import './components/lib/Badge.module.scss';
import './components/Error/PageError.module.scss';
import './components/lib/Layout/MainColumn.module.scss';
import './components/lib/Layout/MainSection.module.scss';
import './components/RouterLink.module.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { RouteProvider, config } from './routes';
import { Routes } from './router/Routes';
import { reportWebVitals } from './utils/reportWebVitals';
import { ErrorBoundary } from './components/ErrorBoundary';
import { ClickTrackingBoundary } from './components/ClickTrackingBoundary';

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <RouteProvider>
          <ClickTrackingBoundary>
            <Routes config={config} />
          </ClickTrackingBoundary>
        </RouteProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
