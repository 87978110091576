import { createAxiosClient } from '@eftours/web-logger-typescript/axios';
import { resolve } from './config';
import { logger } from './logger';

const axiosInstance = createAxiosClient({
  serviceName: 'TravelerExperience',
  logger,
  config: {
    withCredentials: true,
    baseURL: resolve(process.env.REACT_APP_BACKEND_URL),
  },
});

// Always send the full referrer path to the api as a query param to be used when constructing
// returnTo or forwardTo urls when creating links that will eventually link back to the current page.
// With the way our app routing works, the referer header doesn't display the actual full href for the
// app request. It's not until react router starts making page-specific requests does the referer
// actually show the full href in the user's browser.
axiosInstance.interceptors.request.use((config) => {
  config.params = config.params || {};
  config.params['referrerPath'] = window.location.pathname;
  return config;
});

export { axiosInstance as axios };
