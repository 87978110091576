import classnames from 'classnames';
import { DivProps } from '../_types';
// Including AppFooter at Main level so footer doesn't flash at top of page for any new page load
import { AppFooter } from '../../../Layout/AppFooter';

/* We configure tooltips at this level because it should be done once for the page, but must live inside react-router routes */
import { Tooltip } from '../Tooltip/Tooltip';

import styles from './Main.module.scss';

type MainProps = {
  mainFooter?: React.ReactNode;
  fullPage?: boolean;
} & DivProps;

const Main = ({ children, mainFooter, className = '', fullPage, ...htmlProps }: MainProps) => {
  return (
    <div className={classnames(styles.offsetContainerForNav, { [styles.fullPage]: fullPage })}>
      <Tooltip />
      <div className={classnames(styles.mainWrapperInner, className)} {...htmlProps}>
        <main className='ef-row -x-center -y-start'>{children}</main>
      </div>
      {mainFooter}
      <AppFooter fullPage={fullPage} />
    </div>
  );
};

export { Main };
