import React from 'react';
import { ErrorBoundary as CoreErrorBoundary } from '@eftours/web-logger-typescript/react';
import { logger } from '../utils/logger';
import { ApplicationError } from './Error/ApplicationError';

type ErrorBoundaryProps = {
  renderError?: () => React.ReactNode;
  children: React.ReactNode;
};

/**
 * ErrorBoundary is a thin wrapper around the error boundary available in
 * @eftours/web-logger-typescript/react. Without additional configuration,
 * it uses the application logger and a default error fallback UI.
 */
const ErrorBoundary = ({ renderError = () => <ApplicationError />, children }: ErrorBoundaryProps) => (
  <CoreErrorBoundary logger={logger} renderError={renderError}>
    {children}
  </CoreErrorBoundary>
);

export { ErrorBoundary };
