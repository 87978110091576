import { FormattedMessage } from 'react-intl';
import { useIntlAttribute } from '../../hooks/useIntlAttribute';
import { usePageTitle } from '../../hooks/usePageTitle';
import { Button } from '../lib/Button';
import { ErrorWrapper } from './ErrorWrapper';
import { HelpCenterLink } from './HelpCenterLink';

/**
 * ApplicationError produces a full screen error page for a fatal application-level error.
 * One of two successors to AppError, along with PageError.
 */
const ApplicationError = ({ errorCode, errorMessage }: { errorCode?: string | number; errorMessage?: string }) => {
  return (
    <ErrorWrapper>
      <PageTitle />
      {(errorCode || errorMessage) && (
        <div>
          <FormattedMessage id='PageError.code' defaultMessage='Error: {errorCode}' values={{ errorCode }} />
          <p>{errorMessage}</p>
        </div>
      )}
      <h3>
        <FormattedMessage id={'ApplicationError.heading'} defaultMessage={'Yikes! Sorry about this...'} />
      </h3>
      <p>
        <FormattedMessage
          id='ApplicationError.body'
          defaultMessage="The site has encountered an unexpected error. We're working to resolve the issue, and will have things back up
      and running shortly. In the meantime, we recommend refreshing the page."
        />
      </p>
      <p>
        <FormattedMessage
          id='ApplicationError.contact'
          defaultMessage={
            'If you need immediate assistance with an upcoming tour, please visit the {link} or contact Traveler Support. Thank you for your patience!'
          }
          values={{
            link: <HelpCenterLink />,
          }}
        />
      </p>
      <div className='ef-row -x-center'>
        <BackToMyToursButton />
      </div>
    </ErrorWrapper>
  );
};

const PageTitle = () => {
  const title = useIntlAttribute({ id: 'ApplicationError.title', defaultMessage: 'Error' });
  usePageTitle(title);
  return null;
};

const BackToMyToursButton = () => {
  const myToursLink = useIntlAttribute({
    id: 'ApplicationError.myToursLink',
    defaultMessage: 'https://www.eftours.com/personal/my-tours',
  });
  return (
    <Button primary as='a' href={myToursLink} target='_blank'>
      <FormattedMessage id='ApplicationError.button' defaultMessage='Back to your tours' />
    </Button>
  );
};
export { ApplicationError };
