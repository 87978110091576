import { AsyncRequestState, useAsyncRequestState } from './useAsyncRequestState';
import { ComparePredicate, useLocalCacheList } from './useLocalCacheList';

/**
 * A type to extend Page provider data with to recognize that if we're including this value
 * there's a dependency on knowing when a given phx2web item's last integration date was,
 * and that we'll be using it to check a cache here on the frontend
 */
type PaxOrderIntegrationDate = {
  paxOrderLastUpdatedAt: string;
};

type GroupPaxOrdersIntegrationDate = {
  groupPaxOrdersLastUpdatedAt: string;
};

type usePhx2WebUpdateCacheOptions<TData> = Pick<
  UseAsyncRequestWithCacheProps<TData>,
  'phx2webId' | 'itemLastUpdatedAt' | 'cacheKeyPrefix'
>;

/**
 * thin wrapper around useLocalCacheList for consumers to access phx2web-based cache items, usually paxOrders
 *
 * @param cacheKeyPrefix - prefix to use for cacheKey when accessing cache items, combines with phx2webId
 * @param phx2webId - cache is set in the context of a phx2webId, usually paxOrderId
 * @param itemLastUpdatedAt - cache will expire entries that were created before this ISO Date string, the phx2web item's IntegrationDate
 */
function usePhx2WebUpdateCache<TData>({
  cacheKeyPrefix,
  phx2webId,
  itemLastUpdatedAt,
}: usePhx2WebUpdateCacheOptions<TData>) {
  const cacheKey = `${cacheKeyPrefix}-${phx2webId}`;
  return useLocalCacheList<TData>(cacheKey, itemLastUpdatedAt);
}

type UseAsyncRequestWithCacheProps<TData, TReqParams = undefined> = {
  phx2webId: number | string;
  itemLastUpdatedAt: string;
  data: TData[];
  cacheKeyPrefix: string;
  compareCacheItem: ComparePredicate<TData>;
  request: (reqParams?: TReqParams) => Promise<void>;
};

/**
 * A utility hook to simplify this common pattern of wrapping an async request, and caching this request when successful
 * in local storage. We return the wrapped request that the consuming code can choose when to execute (e.g., a button press),
 * as well as the state of that request.
 *
 * In general, usage follows the pattern below:
 *
 * 0) the relevant API response data includes the integrationDate of the item to be updated, usually a paxOrder
 * 1) The consuming component wraps an update request in useCachedPhx2WebUpdate
 * 2) The update call is triggered by some user action.
 * 3) If the axios request is successful, the supplied data is cached in localStorage with the timestamp of the request.
 *    If it is not successful, nothing is cached.
 * 4) On subsequent reloads, the consuming component checks the timestamp in the cache against the integrationDate in the API response
 *    and takes some action if the update timestamp is earlier than the integration date, like blocking another update request.
 *    This signifies that the update is still pending and the phx2web data returned is stale.
 *
 * @param phx2webId - cache is set in the context of a phx2webId
 * @param itemLastUpdatedAt - cache will expire entries that were created before this ISO Date string, the phx2web item's IntegrationDate
 * @param data - a list of <TData> data that you need to cache
 * @param cacheKeyPrefix - prefix to use for cacheKey when accessing cache items, combines with phx2webId
 * @param compareCacheItem - a match fn for the cache to use, determines if a data item is in the cache
 * @param request - the async request you want to make
 */
const useCachedPhx2WebUpdate = <TData, TReqParams = undefined>({
  phx2webId,
  itemLastUpdatedAt,
  data,
  cacheKeyPrefix,
  compareCacheItem,
  request,
}: UseAsyncRequestWithCacheProps<TData, TReqParams>) => {
  const cache = usePhx2WebUpdateCache<TData>({
    cacheKeyPrefix,
    phx2webId,
    itemLastUpdatedAt,
  });

  // create the request we need to monitor
  const {
    request: wrappedRequest,
    requestState,
    error,
  } = useAsyncRequestState(async (reqParams?: TReqParams) => {
    // add to cache *before* success response in case the user navigates away from the page after submitting, before we know if it succeeded,
    // In the event of a failure, the cache will automatically expire a cache if it's older than several minutes
    // We also do this to ensure it's before the next PaxOrder integration date, otherwise we risk a race condition
    // where the success response back to the server + setting the cache could be slower than the phx2web reintegration
    // (i.e., our cache Added date would be after a successfully reintegrated PaxOrder document)
    data.forEach((item) => {
      cache.add(item);
    });

    await request(reqParams).catch((reason) => {
      // if there's an error, remove item from the cache
      cache.remove(compareCacheItem);
      //re-throw so wrapper recognizes error
      throw reason;
    });
  });

  return {
    request: wrappedRequest,
    // Downstream, isComplete is either a complete request, or an unstarted request
    // with a known, pending-change, added matchCode
    isComplete:
      requestState === AsyncRequestState.COMPLETE ||
      (requestState === AsyncRequestState.NEVER && cache.contains(compareCacheItem)),
    isLoading: requestState === AsyncRequestState.LOADING,
    isError: requestState === AsyncRequestState.ERROR,
    error,
  };
};

export type {
  UseAsyncRequestWithCacheProps,
  usePhx2WebUpdateCacheOptions,
  PaxOrderIntegrationDate,
  GroupPaxOrdersIntegrationDate,
};

export { useCachedPhx2WebUpdate, usePhx2WebUpdateCache };
