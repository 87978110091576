import { Progress } from '@ef-global/web-ui-react/lib/components/Progress';
import { useEffect } from 'react';
import { clientEvent } from '../utils/logger';

type LoadingBarProps = {
  className?: string;
};

/**
 * LoadingBar produces a slim loading bar to be used at the top of the page or
 * within a main content area. It's not meant to be a long-term loading indicator,
 * but we needed something for PageProvider POC.
 */
const LoadingBar = ({ className = 'u-mb-l' }: LoadingBarProps) => {
  // Log the estimated duration the loading bar is show to customers.
  // This will run once on mount and once on unmount.
  useEffect(() => {
    const loadingStart = performance.now();
    return () => {
      // FIXME: some folks on the internet say we need to wait a tick if we
      // want to actually trigger this when the component has been unmounted vs
      // being sent to be unmounted.
      setTimeout(() => {
        const loadingEnd = performance.now();
        clientEvent('loading', {
          durationMs: Math.round(loadingEnd - loadingStart),
        });
      });
    };
  }, []);

  return (
    <Progress bar value={60} className={className}>
      <Progress.Bar indeterminate size='s'>
        <Progress.Track />
        <Progress.Indicator />
      </Progress.Bar>
    </Progress>
  );
};

export { LoadingBar };
