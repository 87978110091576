import { useState } from 'react';

/**
 * AsyncRequestState is an enum to describe the current loading state of
 * an async request.
 */
enum AsyncRequestState {
  NEVER,
  LOADING,
  COMPLETE,
  ERROR,
}

/**
 * A helper hook for components that need to make an async request, this wraps a request and returns it
 * for the user to decide when to execute the async request (e.g., a button click, or useEffect). It provides a common
 * interface to know what state the request is currently in:
 *  - not requested (NEVER)
 *  - LOADING
 *  - COMPLETE
 *  - ERROR
 * It returns response data, and catches and returns an error if there is one.
 */
const useAsyncRequestState = <T, TReqParams>(request: (reqParams?: TReqParams) => Promise<T>) => {
  const [data, setData] = useState<T | null>(null);
  const [requestState, setRequestState] = useState(AsyncRequestState.NEVER);
  const [error, setError] = useState<any | null>(null);

  const wrappedRequest = async (reqParams?: TReqParams) => {
    setRequestState(AsyncRequestState.LOADING);

    // we take advantage of the promise/then/catch syntax because it gives us `any` errors instead
    // of `unknown` errors in the try/catch imperative syntax. Easier to work with.
    await request(reqParams)
      .then((response) => {
        setData(response);
        setError(null);
        setRequestState(AsyncRequestState.COMPLETE);
      })
      .catch((err) => {
        setData(null);
        setError(err);
        setRequestState(AsyncRequestState.ERROR);
      });
  };

  return { request: wrappedRequest, requestState, error, data };
};

export { useAsyncRequestState, AsyncRequestState };
