import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import styles from './Tooltip.module.scss';

type TooltipProps = {
  className?: string;
};

/*
  This component should be placed once on the page to initialize and configure react-tooltip. 
  Individual tooltips are then created via <InfoIconWithTooltip />

  See html related security note here: https://www.npmjs.com/package/react-tooltip#security-note
  tl;dr: don't put raw user input into a tooltip with html=true
*/
const Tooltip = ({ className }: TooltipProps) => {
  const cssClasses = classNames(styles.Tooltip, className);

  return (
    <ReactTooltip
      className={cssClasses}
      backgroundColor='white'
      effect='solid'
      multiline={true}
      scrollHide={false}
      clickable={true}
      html={true}
      delayHide={1000}
      place='right'
      /** FIXME: tooltips are still sometimes getting cut off on mobile
       * place='right' fixes it in the three places that we're currently
       * using a tooltip, but it's not a great solution going forward
       */
    />
  );
};

export { Tooltip };
