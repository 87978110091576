import { useRoutes, RouteObject as ReactRouterRouteObject } from 'react-router-dom';
import { RouteConfig } from './config';

/**
 * routeConfigToRoutes returns an array of react-router v6 compatible RouteObject instances.
 *
 * Optionally, it will wrap route elements with a fallback via Suspense if a fallback is defined
 * on the original RouteConfig or passed via Options.
 */
const routeConfigToRoutes = <TConfig extends RouteConfig>(config: TConfig) => {
  const flat: ReactRouterRouteObject[] = [];

  Object.values(config).forEach((configRoute) => {
    const { children, ...routeProps } = configRoute;
    const converted: ReactRouterRouteObject = { ...routeProps };

    // If we have children, recursively flatten them as well
    converted.children = children !== undefined ? routeConfigToRoutes(children) : undefined;

    // Add it to our converted route object array
    flat.push(converted);
  });

  return flat;
};

/**
 * createRoutes translates a RouteConfig instance into a renderable routes.
 *
 * Internally, the route config is copied and flattened. It is then passed into
 * react-router v6's useRoutes.
 *
 * see: https://reactrouter.com/docs/en/v6/api#useroutes
 */
const createRoutes = <TConfig extends RouteConfig>(config: TConfig) => {
  const routes = routeConfigToRoutes(config);
  return useRoutes(routes);
};

type RouterProps<T extends RouteConfig> = {
  config: T;
};

const Routes = <T extends RouteConfig>({ config }: RouterProps<T>) => {
  return createRoutes(config);
};

export { Routes };
