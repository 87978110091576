import React, { Suspense } from 'react';
import { LinkProps, Navigate } from 'react-router-dom';
import { useAppContext } from './AppProvider';
import { useHasCachedSignatures } from './pages/BookingConditions/BookingConditionsProvider';
import { logger } from './utils/logger';

type RequireSignaturesToProps = {
  paxOrderId: number;
};

type RequireSignaturesProps = {
  to: (props: RequireSignaturesToProps) => LinkProps['to'];
  children: React.ReactNode;
};

/**
 * RequireSignatures checks if signaturesRequired is truthy and redirects using
 * the provided `to` render prop. A render prop is used to avoid a circular dep
 * on routes.tsx.
 *
 * Internally, `useHasCachedSignatures` is used. If any value is cached,
 * this code assumes all signatures are available.
 */
const RequireSignatures = ({ to, children }: RequireSignaturesProps) => {
  const { requiresSignatures, paxOrderId, isImpersonatedUser, paxOrderLastUpdatedAt } = useAppContext().data;
  const hasCachedSignatures = useHasCachedSignatures({
    paxOrderId,
    paxOrderLastUpdatedAt,
  });

  // Redirect if isImpersonatedUser is false, requiredSignatures is true, and
  // hasCachedSignatures is false
  if (isImpersonatedUser === false && requiresSignatures === true && hasCachedSignatures === false) {
    return <Navigate to={to({ paxOrderId })} />;
  }

  return <>{children}</>;
};

type LazyPageComponentProps = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

/**
 * PageWithSuspense is a component to wrap a lazy loaded component with Suspense.
 * It exists to centralize our default fallback for loading JS chunks.
 */
const PageWithSuspense = ({ children, fallback = <></> }: LazyPageComponentProps) => {
  return <Suspense fallback={fallback}>{children}</Suspense>;
};

type PageOptions = {
  bookingConditionsRedirect: RequireSignaturesProps['to'];
};

type PageProps = {
  name: string;
  children: React.ReactNode;
  requireBookingConditions?: true;
};

/**
 * createPageWrapper is a component factory to create a Page component to wrap children in
 * Suspense and additional page-render requirements, such as booking conditions. It is a
 * factory to remove the dependency on useRoutes within this module.
 *
 * @see routes.tsx for example usage
 */
const createPageWrapper = ({ bookingConditionsRedirect }: PageOptions) => {
  const Page = ({ name, requireBookingConditions, children }: PageProps) => {
    let wrappedChildren = children;

    // Set a correlation id per page
    logger.setCorrelationId();
    logger.appendMeta({ route: name });

    if (requireBookingConditions) {
      wrappedChildren = <RequireSignatures to={bookingConditionsRedirect}>{wrappedChildren}</RequireSignatures>;
    }

    return <PageWithSuspense>{wrappedChildren}</PageWithSuspense>;
  };
  return Page;
};

export { PageWithSuspense, createPageWrapper };
