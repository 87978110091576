import React, { useContext } from 'react';
import { createRouteGenerators, RouteConfigToPathGenerators } from './generator';
import { RouteConfig } from './config';

const createRouteGeneratorsContext = <T extends RouteConfig>(config: T) => {
  const routeMap = createRouteGenerators(config);
  return React.createContext(routeMap);
};

type WrapperRouteProviderProps = {
  children: React.ReactNode;
};

/**
 * createRouteProvider is a factory to create a RouteProvider component and `useRoutes` hook
 * for the provided RouteConfig object. This provides typed access to routes via `useRoutes`
 * for all child components of the returns RouteProvider.
 */
const createRouteProvider = function <T extends RouteConfig>(
  config: T
): [() => RouteConfigToPathGenerators<T>, React.ComponentType<WrapperRouteProviderProps>] {
  const RouteGeneratorsContext = createRouteGeneratorsContext<T>(config);
  const useRoutes = () => useContext(RouteGeneratorsContext);

  const WrapperRouteProvider = ({ children }: WrapperRouteProviderProps) => {
    return (
      <RouteGeneratorsContext.Provider value={createRouteGenerators(config)}>
        {children}
      </RouteGeneratorsContext.Provider>
    );
  };

  return [useRoutes, WrapperRouteProvider];
};

export { createRouteProvider };
