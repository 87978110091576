import { useEffect } from 'react';
import { useIntlAttribute } from './useIntlAttribute';

const usePageTitle = (title: string) => {
  const pageTitle = useIntlAttribute({ id: 'PageTitle', defaultMessage: 'EF Educational Tours' });
  const documentTitle = useIntlAttribute({ id: 'DocumentTitle', defaultMessage: 'EF Educational Tours' });
  useEffect(() => {
    document.title = `${title} | ${pageTitle}`;
    return () => {
      document.title = documentTitle;
    };
  }, [title]);
};
export { usePageTitle };
