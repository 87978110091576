import { AxiosResponse } from 'axios';
import { axios } from './utils/axios';
import { ApplicationError } from './components/Error/ApplicationError';
import { LoadingBar } from './components/LoadingBar';
import { createPageProvider, PageProviderError } from './components/PageProvider';
import { RedirectOnStatus } from './components/RedirectOnStatus';
import { PaxOrderIntegrationDate } from './hooks/usePhx2WebUpdateCache';
import { FeatureFlagValue } from './utils/types';

type Individual = {
  firstName: string;
  lastName: string;
  email?: string;
  // isGroupLeader refers to the loggedInUser's typeCode, not necessarily true that they are the group leader of THIS tour
  isGroupLeader?: boolean;
};

enum StatusCode {
  Actualized = 'AC',
  Waitlisted = 'WL',
  Pending = 'PA',
  OnHold = 'OH',
}

enum CurrencyType {
  USD = 'USD',
  CAD = 'CAD',
}

type NavLinkUrls = {
  referAFriend: string;
  contactUs: string;
  profile: string;
  logOut: string;
  myTours: string;
  browseTours: string;
  helpCenter: string;
  globalRewards: FeatureFlagValue<string>;
};

// FIXME: businessUnit and businessUnitGroup should not be optional. Switch
// once we're migrated to a dedicated endpoint.
// FIXME: We'll want to revisit the structure of this response type if we continue
// to add global values like currencyType. Eventually we should decouple 'settings-related'
// components into their own context.
type AppDataResponse = {
  paxOrderId: number;
  isImpersonatedUser: boolean;
  content?: Record<string, string>;
  currencyType: CurrencyType;
  tourName: FeatureFlagValue<string>;
  traveler: Individual;
  loggedInUser: Individual;
  locale: string;
  groupLeader: Individual;
  tripId: string;
  departureDate: string;
  returnDate: string;
  areDatesConfirmed: boolean;
  requiresSignatures: boolean;
  requiresPassport: boolean;
  homepageUrl: string;
  navLinkUrls: NavLinkUrls;
  statusCode: StatusCode;
  showBlogLink: boolean;
  customerNumber: string;
  contentDeviations: ContentDeviations;
} & PaxOrderIntegrationDate;

type ContentDeviations = {
  showPayments: boolean;
  showTravelProtection: boolean;
  showGroupLeader: boolean;
};

const getAppData = async (paxOrderId: number): Promise<AppDataResponse> => {
  const uri = `/app/${paxOrderId}`;
  const { data } = await axios.get<AppDataResponse>(uri);

  // The API doesn't return the paxOrderId (we sent it).
  data.paxOrderId = paxOrderId;

  return data;
};

const [useAppContext, AppProvider, AppContext] = createPageProvider<AppDataResponse>({
  renderPageLoading: () => <LoadingBar />,
  renderPageError: (err) => <AppProviderError err={err} />,
});

type AppProviderErrorProps = {
  err: PageProviderError;
};

type AppProviderErrorResponseData = {
  additionalData: {
    redirectTo: string;
  };
};

const AppProviderError = ({ err }: AppProviderErrorProps) => {
  const response: AxiosResponse<AppProviderErrorResponseData> = err.rawError?.response;
  return (
    <RedirectOnStatus
      redirectOnStatuses={[401, 403]}
      status={response?.status}
      to={response?.data?.additionalData?.redirectTo}
      replace
    >
      <ApplicationError errorCode={response?.status} errorMessage={err.message} />
    </RedirectOnStatus>
  );
};

const usePaxOrderId = () => useAppContext().data.paxOrderId;

export type { AppDataResponse, Individual };
export { getAppData, useAppContext, usePaxOrderId, AppProvider, AppContext, StatusCode };
