import { FormattedMessage } from 'react-intl';
import { useIntlAttribute } from '../../hooks/useIntlAttribute';
import { Link } from '../lib/Link';

type HelpCenterLinkProps = {
  target?: '_self' | '_blank';
};

const HelpCenterLink = ({ target = '_self' }: HelpCenterLinkProps) => {
  return (
    <Link
      underlinedWithoutColorChange
      href={useIntlAttribute({
        id: 'PageError.helpCenterLink',
        defaultMessage: 'https://www.eftours.com/help-center',
      })}
      target={target}
    >
      <FormattedMessage id='PageError.helpCenter' defaultMessage='Help Center' />
    </Link>
  );
};

export { HelpCenterLink };
