import { NavigateProps, Navigate } from 'react-router-dom';
import React from 'react';

type RedirectOnStatusProps = {
  redirectOnStatuses: number[];
  status?: number;
  children: React.ReactNode;
  to: string | null;
} & Omit<NavigateProps, 'to' | 'state'>;

/**
 * A wrapper where we handle consumer-specified http status codes with a redirect instead of an AppError
 *
 * @param status - an http status code number
 * @param redirectOnStatuses - a list of http status codes (e.g., 403, 500) that we want to treat with a redirect instead of the standard error page
 * @param to - string that is either a react router route, or if an external url, must be used with the `external` boolean param
 * @param replace - from react router NavigateProps
 */
const RedirectOnStatus = ({ status, redirectOnStatuses, children, to, replace }: RedirectOnStatusProps) => {
  if (to && status && redirectOnStatuses.includes(status)) {
    // handle external redirects differently than react router redirects
    if (isExternalUrl(to)) {
      replace ? window.location.replace(to) : window.location.assign(to);
      return null;
    }

    return <Navigate to={to} replace={replace} />;
  }

  // otherwise load wrapped element
  return <>{children}</>;
};

/**
 * Private helper to check if the `to` here is an external URL
 * For details on the regex, see https://stackoverflow.com/a/19709846/2379922
 */
const isExternalUrl = (to: string) => {
  const externalUrlRegex = new RegExp('^(?:[a-z]+:)?//', 'i');
  return externalUrlRegex.test(to);
};

export { RedirectOnStatus };
