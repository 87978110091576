import { axios } from '../../utils/axios';
import { LoadingBar } from '../../components/LoadingBar';
import { createPageProvider } from '../../components/PageProvider';
import {
  PaxOrderIntegrationDate,
  usePhx2WebUpdateCache,
  usePhx2WebUpdateCacheOptions,
} from '../../hooks/usePhx2WebUpdateCache';

const SIGNATURES_CACHE_KEY = 'BOOKING_CONDITIONS_SIGNATURES';

type ContentMeta = {
  uuid: string;
  slug: string;
  publishedAt: string;
};

type AgreementContent = ContentMeta & {
  content: BookingConditionSection[];
};

type SignatureContent = ContentMeta & { content: string };

type BookingConditionSection = {
  section_title: string;
  section_content: string;
  _uid: string;
};

type BookingConditions = { agreement: AgreementContent; signature: SignatureContent };

type SignatureTypeCodes = { typeCodes: string[] };

type BookingConditionsData = BookingConditions & PaxOrderIntegrationDate & SignatureTypeCodes;

const getBookingConditionsData = async (paxOrderId: number): Promise<BookingConditionsData> => {
  const uri = `/signatures/${paxOrderId}`;
  const { data } = await axios.get<BookingConditionsData>(uri);

  return data;
};

const [useBookingConditionsContext, BookingConditionsProvider, BookingConditionsContext] =
  createPageProvider<BookingConditionsData>({
    renderPageLoading: () => <LoadingBar />,
  });

// BookingConditionsUpdateData is the post payload to the API
type BookingConditionsUpdateData = {
  typeCodes: BookingConditionsCacheData;
};

const setBookingConditionsData = async (paxOrderId: number, data: BookingConditionsUpdateData) => {
  const uri = `/signatures/${paxOrderId}/add`;

  await axios.post(uri, data);
};

type BookingConditionsCacheData = BookingConditionsData['typeCodes'];

const cachedSignaturesComparePredicate = (entry: string[]) => entry.length > 0;

/**
 * useHasCachedSignatures is a helper to check if any signatures have been
 * cached. If they have been, return true.
 */
const useHasCachedSignatures = <T extends BookingConditionsCacheData>({
  paxOrderId,
  paxOrderLastUpdatedAt,
}: {
  paxOrderId: usePhx2WebUpdateCacheOptions<T>['phx2webId'];
  paxOrderLastUpdatedAt: usePhx2WebUpdateCacheOptions<T>['itemLastUpdatedAt'];
}) => {
  const cache = usePhx2WebUpdateCache<T>({
    phx2webId: paxOrderId,
    itemLastUpdatedAt: paxOrderLastUpdatedAt,
    cacheKeyPrefix: SIGNATURES_CACHE_KEY,
  });

  // If the cache isn't null, return true.
  return cache.contains(cachedSignaturesComparePredicate);
};

export type { BookingConditionsData, BookingConditionsCacheData, BookingConditionsUpdateData };
export {
  getBookingConditionsData,
  setBookingConditionsData,
  useBookingConditionsContext,
  useHasCachedSignatures,
  cachedSignaturesComparePredicate,
  BookingConditionsProvider,
  BookingConditionsContext,
  SIGNATURES_CACHE_KEY,
};
