import React from 'react';
import classNames from 'classnames';
import { Link as EFLink } from '@ef-global/web-ui-react/lib/components/Link';
import styles from './Link.module.scss';

type LinkProps = React.ComponentProps<typeof EFLink> & { underlinedWithoutColorChange?: boolean };

const Link = ({ className = '', underlinedWithoutColorChange, ...htmlProps }: LinkProps) => (
  <EFLink
    className={classNames(className, { [styles.underlinedWithoutColorChange]: underlinedWithoutColorChange })}
    {...htmlProps}
  />
);

export type { LinkProps };
export { Link };
