import { DateTime } from 'luxon';
import store from 'store2';

const DEFAULT_CACHE_TTL_MINUTES = 5 as const;

type CacheEntry<T> = {
  addedAt: string;
  data: T;
};

function createCacheEntry<T>(data: T, addedAt: DateTime): CacheEntry<T> {
  return {
    data,
    addedAt: addedAt.toUTC().toISO(),
  };
}

type ComparePredicate<T> = (data: T) => boolean;

/**
 * A utility hook for managing a list of expirable CacheItems in local storage.
 *
 *
 * When used, it expects a cacheKey to use to look up and save items to the cache,
 * as well as a ISO DateTime string to use to expire cache items
 *
 * @param cacheKey - lookup key for cache
 * @param expiresAt - UTC ISO date string when cache entries should be expired
 * @param cacheItemTTL - defaults to `DEFAULT_CACHE_TTL_MINUTES` const value. Dictates maximum lifespan of a cache entry (in relation to its `addedAt` date), regardless of value of `expiresAt` date.
 * @returns { add, contains, remove } helper functions as the API, one to add a new cache entry, one to see if we currently have a cache item, ...and one to rule them all
 */
function useLocalCacheList<T>(cacheKey: string, expiresAt: string, cacheItemTTL: number = DEFAULT_CACHE_TTL_MINUTES) {
  /**
   * private helper to get the typed cache list (and filters out expired entries and updates the cache before returning)
   */
  function get() {
    const entries = store.get(cacheKey) as CacheEntry<T>[] | null;
    const filtered = filterOutExpired(entries);
    update(filtered);
    return filtered;
  }

  /**
   * private helper to filter out expired entries, does not update the cache
   */
  function filterOutExpired(entries: CacheEntry<T>[] | null) {
    return entries?.filter((entry) => {
      const cacheCreatedAt = DateTime.fromISO(entry.addedAt, { zone: 'UTC', setZone: true });
      const expiresCacheAt = DateTime.fromISO(expiresAt, { zone: 'UTC', setZone: true });

      // Always expire cache entries after 5 minutes to ensure no cache entries are permanent
      const expireCacheItemAt = cacheCreatedAt.plus({ minutes: cacheItemTTL });
      const isEntryExpired = DateTime.now().toUTC() > expireCacheItemAt.toUTC();

      // Only keep cache entries that aren't expired
      return !isEntryExpired && cacheCreatedAt > expiresCacheAt;
    });
  }

  /**
   * private helper that either sets or removes the cache depending on if we have any data
   */
  function update(entries: CacheEntry<T>[] | undefined) {
    if (entries && entries.length > 0) {
      store.set(cacheKey, entries);
    } else {
      store.remove(cacheKey);
    }
  }

  /**
   * Add a data item to the cache
   */
  function add(data: T, addedAt = DateTime.now()) {
    const entries = get() || [];
    entries.push(createCacheEntry(data, addedAt));
    store.set(cacheKey, entries);
  }

  /**
   * Determine if data is present in your cache list
   *
   * @param compare a function to compare a cache entry's `data` to determine if there's equality
   * @returns if the data in question is present in the cache list
   */
  function contains(compare: ComparePredicate<T>) {
    const entries = get();
    return entries != null && entries.some(({ data }) => compare(data));
  }

  /**
   * Remove an entry from the cache list
   *
   * @param compare a function to compare a cache entry's `data` to determine if there's equality
   */
  function remove(compare: ComparePredicate<T>) {
    // only keep entries that don't match compare
    const entries = get()?.filter((entry) => !compare(entry.data));
    update(entries);
  }

  return { add, contains, remove };
}

export type { ComparePredicate };
export { useLocalCacheList, createCacheEntry, DEFAULT_CACHE_TTL_MINUTES };
